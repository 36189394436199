/** Rubik **/
@font-face {
	font-family: "Rubik";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("/assets/fonts/Rubik/Rubik-Light.ttf");
}
@font-face {
	font-family: "Rubik";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("/assets/fonts/Rubik/Rubik-Regular.ttf");
}
@font-face {
	font-family: "Rubik";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("/assets/fonts/Rubik/Rubik-Bold.ttf");
}

/** Poppins **/
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("/assets/fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("/assets/fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("/assets/fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("/assets/fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("/assets/fonts/Poppins/Poppins-Bold.ttf");
}

/** Montserrat **/
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("/assets/fonts/Montserrat/Montserrat-Regular.ttf");
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("/assets/fonts/Montserrat/Montserrat-Medium.ttf");
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("/assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("/assets/fonts/Montserrat/Montserrat-Bold.ttf");
}

h1 {
	font-weight: 300;
	line-height: normal;
}

h2 {
	font-weight: 500;
	line-height: normal;
}

h3 {
	font-weight: 400;
	line-height: normal;
}

h4 {
	font-weight: 400;
	line-height: normal;
}

h5 {
	font-weight: 300;
	line-height: normal;
}
