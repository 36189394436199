/* ==========================================================================
   INDEX
   ========================================================================== */
.index {
	padding: 38px 0 94px;
	color: white;
	position: relative;
	z-index: 1;
	@include media-breakpoint-up(md) {
		padding: 120px 0;
	}
	@include media-breakpoint-up(lg) {
		padding: 140px 0 180px;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 229px;
		// padding-bottom: 240px;
	}
	@include media-breakpoint-up(xxl) {
		// padding-bottom: 295px;
	}

	&::after {
		@include pseudo;
		position: absolute;
		top: 0;
		right: -1rem;
		bottom: 0;
		left: -1rem;
		z-index: 0;
		background: $color-blue-3;
		@include media-breakpoint-up(xss) {
			width: calc(100vw + 2px);
			right: auto;
			left: calc(50% - 1px);
			transform: translateX(-50%);
		}
		@include media-breakpoint-up(md) {
			background-image: url("#{$img}/bg/index-1.webp"), url("#{$img}/bg/index-2.webp");
			background-position:
				left top,
				right top;
			background-repeat: no-repeat, no-repeat;
			background-size:
				auto 92%,
				auto 88%;
		}
		@include media-breakpoint-down(md) {
			background-image: url("#{$img}/bg/index-0.webp");
			background-position: right bottom;
			background-repeat: no-repeat;
			background-size: auto auto;
		}
	}

	> .row {
		align-items: center;
		position: relative;
		z-index: 1;
	}

	&__content {
		max-width: 400px;
		margin: 0 auto 38px;
		font-size: 14px;
		line-height: 25px;
		letter-spacing: -0.005em;
		text-align: center;
		color: $color-blue-6;
		@include media-breakpoint-up(md) {
			max-width: none;
			margin-bottom: 0;
			font-size: 16px;
			line-height: 150%;
			font-weight: 300;
			position: relative;
		}
		@include media-breakpoint-up(lg) {
			font-size: 18px;
		}
		@include media-breakpoint-up(xl) {
			font-size: 20px;
		}
		@include media-breakpoint-up(xxl) {
			padding: 0 10px;
			// margin-top: 72px;
			//font-size: 24px;
			// left: 56px;
		}

		h1 {
			margin-bottom: 21px;
			//font-size: 32px;
			line-height: 125%;
			font-weight: 600;
			color: $color-white;
			@include media-breakpoint-up(md) {
				margin-bottom: 30px;
				//font-size: 28px;
				letter-spacing: 0.01em;
			}
			@include media-breakpoint-up(lg) {
				margin-bottom: 45px;
				//font-size: 36px;
			}
			@include media-breakpoint-up(xl) {
				//font-size: 48px;
				margin-bottom: 60px;
			}

			em {
				font-style: normal;
				color: $color-orange;
			}
		}

		p {
			margin-bottom: 0;
		}

		> *:last-child {
			margin-bottom: 0 !important;
		}
		> p:last-child {
			@include media-breakpoint-up(lg) {
				margin-bottom: 10px !important;
			}
			@include media-breakpoint-up(xl) {
				margin-bottom: 20px !important;
			}
			@include media-breakpoint-up(xxl) {
				margin-bottom: 40px !important;
			}
		}
	}

	&__buttons {
		@include media-breakpoint-down(md) {
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
		}
		@include media-breakpoint-up(md) {
			margin-top: 30px;
		}
		@include media-breakpoint-up(lg) {
			margin-top: 45px;
		}
		@include media-breakpoint-up(xl) {
			margin-top: 60px;
		}
		@include media-breakpoint-up(xxl) {
			margin-top: 94px;
		}
	}

	.btn {
		--bs-btn-padding-x: 5px;
		margin: 0 2px;
		min-width: 125px;
		box-shadow: 0 4px 10px rgba($color-black, 0.5);

		@include media-breakpoint-up(xl) {
			min-width: 177px;
			font-size: 20px;
		}

		&.btn-primary {
			--bs-btn-bg: #bedced;
			--bs-btn-border-color: #bedced;
			--bs-btn-color: #{$color-blue-1};
			--bs-btn-hover-bg: #a4c3d5;
			--bs-btn-hover-border-color: #a4c3d5;
			--bs-btn-hover-color: #{$color-blue-1};
			--bs-btn-active-bg: #81a6bb;
			--bs-btn-active-border-color: #81a6bb;
			--bs-btn-active-color: #{$color-blue-1};
			--bs-btn-disabled-bg: #bedced;
			--bs-btn-disabled-border-color: #bedced;

			&.btn-arrow {
				&::after {
					background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzFGM0E2OCIgZD0ibTEwLjQ3NyA2LjE2Ny00LjQ3LTQuNDdMNy4xODUuNTE5IDEzLjY2NyA3bC02LjQ4MiA2LjQ4Mi0xLjE3OC0xLjE3OCA0LjQ3LTQuNDdILjMzM1Y2LjE2N2gxMC4xNDRaIi8+PC9zdmc+");
				}
			}
		}
	}

	&__image {
		color: #325691;
		display: flex;
		justify-content: center;
		padding: 0;
		margin: 0 auto;
		position: relative;

		@include media-breakpoint-down(md) {
			max-width: 302px;
			margin-bottom: 83px;
		}

		@include media-breakpoint-up(xl) {
			max-width: 717px;
			margin-left: 0;
		}

		a.video-play {
			display: block;
			position: absolute;
			display: flex;
			justify-content: center;
			z-index: 2;
			height: 100%;
			width: 200px;
			background-size: 100px auto;
			background-position: 61.75% 48.5%;
			background-image: url(/assets/img/landing/play.webp);
			background-repeat: no-repeat;
			transition: transform 0.2s;
			transform-origin: center center;
			&:hover {
				-ms-transform: scale(1.2); /* IE 9 */
				-webkit-transform: scale(1.2); /* Safari 3-8 */
				transform: scale(1.2);
			}

			@include media-breakpoint-down(md) {
				width: 200px;
				background-size: 100px auto;
				background-position: 58% 48.5%;
			}
			@include media-breakpoint-up(lg) {
				width: 400px;
				background-size: 120px auto;
				background-position: 59% 48.5%;
			}
		}

		img {
			width: 100%;
			height: auto;
			display: block;
			position: relative;
			z-index: 1;
			@include user-select;

			&.img-mobile {
				@include media-breakpoint-up(lg) {
					display: none;
				}
			}

			&.img-desktop {
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}

			&.video-play-image {
				height: 8rem;
				width: auto;
			}
		}
	}
}
