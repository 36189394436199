/* ==========================================================================
   PAGE
   ========================================================================== */
.page {
  &.homepage {
    >.container {
      display: flex;
      flex-direction: column;
    }
  }
}
