/* ==========================================================================
   HERO
   ========================================================================== */
.hero {
	color: white;
	position: relative;
	z-index: 1;

	&::after {
		@include pseudo;
		position: absolute;
		top: 0;
		right: -1rem;
		bottom: 0;
		left: -1rem;
		z-index: 1;
		background: $color-blue-3;
		@include media-breakpoint-up(xss) {
			width: calc(100vw + 2px);
			right: auto;
			left: calc(50% - 1px);
			transform: translateX(-50%);
		}
		@include media-breakpoint-up(md) {
			background-image: url("#{$img}/bg/hero-1.webp"), url("#{$img}/bg/hero-2.webp");
			background-position:
				left top,
				right top;
			background-repeat: no-repeat, no-repeat;
			background-size:
				auto 100%,
				auto 91%;
		}
	}

	> .row {
		min-height: 158px;
		display: flex;
		align-content: center;
		position: relative;
		z-index: 3;
		@include media-breakpoint-up(md) {
			min-height: 260px;
		}
		@include media-breakpoint-up(lg) {
			min-height: 320px;
		}
		@include media-breakpoint-up(xl) {
			min-height: 380px;
		}
		@include media-breakpoint-up(xxl) {
			min-height: 432px;
		}
	}

	&__content {
		max-width: 400px;
		padding: 1.5rem 0;
		margin-right: auto;
		margin-left: auto;
		font-size: 12px;
		letter-spacing: -0.005em;
		text-align: center;
		color: $color-blue-6;
		@include media-breakpoint-up(md) {
			padding-top: 60px;
			font-size: 14px;
			line-height: 150%;
			font-weight: 300;
		}
		@include media-breakpoint-up(lg) {
			padding-top: 70px;
			font-size: 15px;
			line-height: 22px;
		}
		@include media-breakpoint-up(xl) {
			max-width: 680px;
			padding-top: 100px;
			font-size: 20px;
			line-height: 150%;
		}
		@include media-breakpoint-up(xxl) {
			padding-top: 130px;
		}

		h1 {
			//font-size: 26px;
			font-weight: 500;
			letter-spacing: 0.005em;
			color: $color-white;
			@include media-breakpoint-up(md) {
				font-size: 32px;
			}
			@include media-breakpoint-up(lg) {
				margin-bottom: 0.8em;
				font-size: 40px;
			}
			@include media-breakpoint-up(xl) {
				font-size: 48px;
				line-height: 60px;
			}
			@include media-breakpoint-up(xxl) {
				margin-bottom: 0.9167em;
			}
		}

		> *:last-child {
			margin-bottom: 0 !important;
		}
		> p:last-child {
			@include media-breakpoint-up(lg) {
				margin-bottom: 10px !important;
			}
			@include media-breakpoint-up(xl) {
				margin-bottom: 20px !important;
			}
			@include media-breakpoint-up(xxl) {
				margin-bottom: 40px !important;
			}
		}
	}

	&--smaller {
		.hero__content {
			@include media-breakpoint-up(md) {
				font-size: 15px;
				line-height: 22px;
			}
			@include media-breakpoint-down(xl) {
				br {
					display: none;
				}
			}
			@include media-breakpoint-up(xxl) {
				padding-top: 148px;
			}

			h1 {
				@include media-breakpoint-up(xl) {
					font-size: 40px;
				}
			}
		}
	}

	&--error {
		min-height: 100vh;
		padding-top: 320px;
		padding-bottom: 70px;
		text-align: center;
		color: #878a99;
		font-size: 13px;
		line-height: 20px;
		position: relative;
		z-index: 10;
		@include media-breakpoint-up(md) {
			padding-top: 400px;
		}
		@include media-breakpoint-up(xl) {
			padding-top: 498px;
		}

		&::before {
			@include pseudo;
			position: absolute;
			top: -200px;
			right: -1rem;
			bottom: 0;
			left: -1rem;
			z-index: -2;
			background: #d7f4ff;
			@include media-breakpoint-up(xss) {
				width: calc(100vw + 2px);
				right: auto;
				left: calc(50% - 1px);
				transform: translateX(-50%);
			}
		}

		&::after {
			height: 200px;
			background: url("#{$img}/bg/hero-3.webp") 0 0 no-repeat;
			background-size: 100% 100%;
			z-index: -1;
			@include media-breakpoint-up(md) {
				height: 300px;
			}
			@include media-breakpoint-up(xl) {
				height: 380px;
			}
		}

		> .row {
			min-height: 0;
			position: static;
			align-items: flex-start;
		}

		p {
			width: 390px;
			max-width: 100%;
			margin-right: auto;
			margin-left: auto;
			margin-bottom: 0;
		}

		h2 {
			margin-bottom: 2px;
			font: 400 80px/98px $font-secondary;
			color: $color-dark;
		}

		h3 {
			margin-bottom: 12px;
			//	font: 600 18px/24px $font-secondary;
			color: $color-dark;
			letter-spacing: -0.01em;
			@include media-breakpoint-up(md) {
				//	font-size: 23px;
				line-height: 28px;
			}
		}

		&__copy {
			font-size: 13px;
			text-align: center;
			position: absolute;
			bottom: 20px;
			left: 0;
			right: 0;
			z-index: 10;
		}

		&__pic {
			width: 212px;
			height: auto;
			margin: 0 auto;
			position: absolute;
			top: 38px;
			left: 0;
			right: 0;
			z-index: 4;
			@include media-breakpoint-up(md) {
				width: 260px;
				top: 94px;
			}
			@include media-breakpoint-up(xl) {
				width: 312px;
				top: 138px;
			}
		}

		.btn {
			margin-top: 24px;
			padding: 8px 15px;
			font-size: 13px;

			&::before {
				@include pseudo;
				width: 13px;
				height: 13px;
				margin-right: 4px;
				display: inline-block;
				vertical-align: middle;
				//background: url("#{$img}/landing/home.webp") 0 0 no-repeat;
				background-size: contain;
			}
		}

		&__500 {
			padding-top: 320px;
			@include media-breakpoint-up(md) {
				padding-top: 450px;
			}
			@include media-breakpoint-up(xl) {
				padding-top: 575px;
			}

			h2 {
				color: $color-orange;
				//font-size: 120px;
				line-height: 200px;
				font-weight: 600;
				position: absolute;
				top: 100px;
				left: 0;
				right: 0;
				text-align: center;
				z-index: 3;
				@include media-breakpoint-up(md) {
					//font-size: 200px;
					line-height: 280px;
					top: 180px;
				}
				@include media-breakpoint-up(xl) {
					//font-size: 250px;
					line-height: 305px;
					top: 267px;
				}
			}

			h3 {
				margin-bottom: 8px;
				//font-size: 20px;
				line-height: 24px;
			}

			.hero--error__pic {
				width: 200px;
				top: 138px;
				@include media-breakpoint-up(md) {
					width: 250px;
					top: 240px;
				}
				@include media-breakpoint-up(xl) {
					width: 300px;
					top: 325px;
				}
			}

			.btn {
				margin-top: 16px;
			}
		}
	}
}
