/* ==========================================================================
   PARTNERS
   ========================================================================== */

/* Marquee styles */
.partners {
	position: relative;

	.marquee {
		--gap: 5rem;
		position: relative;
		display: flex;
		overflow: hidden;
		user-select: none;
		gap: var(--gap);
		background: $color-blue;

		width: calc(100vw + 2px);
		//margin: 20 0;
		right: auto;
		left: calc(50% - 1px);
		transform: translateX(-50%);

		@include media-breakpoint-up(xss) {
			margin: 0;
			right: auto;
			transform: translateX(-50%);
		}
		@include media-breakpoint-up(md) {
			padding: 15px 40px;
		}
		@include media-breakpoint-up(xxl) {
			padding: 20px 50px;
		}

		&::before,
		&::after {
			width: 10%;
			height: 100%;
			position: absolute;
			background-image: linear-gradient(to right, rgba($color-blue, 1) 0%, rgba($color-blue, 0) 100%);
			content: "";
			z-index: 2;
			pointer-events: none;
		}
		&::before {
			left: 0;
			top: 0;
		}
		&::after {
			right: 0;
			top: 0;
			transform: rotateZ(180deg);
		}
	}

	.marquee__content {
		background: $color-blue;
		margin: auto 0;
		flex-shrink: 0;
		display: flex;
		justify-content: space-around;
		gap: var(--gap);
		min-width: 100%;
		animation: scroll 60s linear infinite;
		list-style-type: none;

		li {
			padding-right: 1rem;
			margin: auto 0;
		}
	}

	@keyframes scroll {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(calc(-100% - var(--gap)));
		}
	}
}
