/* ==========================================================================
   FAQ
   ========================================================================== */
.faq {
  h4 {
    padding: 4px 0;
    margin-bottom: 16px;
    position: relative;
    line-height: 24px;
    @include media-breakpoint-down(xl) {
      margin-bottom: 20px;
    }

    img {
      margin-right: 12px;
      height: 24px;
      display: inline-block;
    }
  }

  .accordion {
    @include media-breakpoint-down(xl) {
      margin-bottom: 3rem;
    }
  }
}
