/* ==========================================================================
   NAVBAR
   ========================================================================== */
.navbar {
	@include media-breakpoint-up(md) {
		padding: 0;
	}

	.container-fluid {
		max-width: 1650px;
		position: relative;
	}

	&-brand {
		--bs-navbar-brand-padding-y: 0;
		--bs-navbar-brand-margin-end: 0;
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 1rem;
			left: 1rem;
		}
		@include media-breakpoint-up(xl) {
			top: 32px;
			left: 2rem;
		}
		@include media-breakpoint-up(xxl) {
			top: 46px;
			left: 4rem;
		}

		img {
			width: 146px;
			height: auto;
			display: block;
			@include user-select;
			@include media-breakpoint-up(md) {
				width: 180px;
			}
			@include media-breakpoint-up(lg) {
				width: 240px;
			}
			@include media-breakpoint-up(xl) {
				width: 300px;
			}
			@include media-breakpoint-up(xxl) {
				width: 406px;
			}
		}
	}

	&-toggler {
		--bs-navbar-toggler-padding-x: 9px;
		--bs-navbar-toggler-padding-y: 5px;
		--bs-border-width: 0;
		--bs-navbar-toggler-border-radius: 0;
		--bs-navbar-toggler-transition: none;
		--bs-navbar-toggler-icon-bg: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTEuNSAxN2gyMi0yMloiLz48cGF0aCBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xLjUgMWgyMm0tMjIgOGgyMm0tMjIgOGgyMiIvPjwvc3ZnPg==");
		position: relative;
		box-shadow: none !important;
		outline: none;
		@include media-breakpoint-up(md) {
			right: 7px;
		}
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 1rem;
			right: 1rem;
		}
		@include media-breakpoint-up(lg) {
			top: 1.5rem;
			right: 1.5rem;
		}

		&-icon {
			background-size: 24px 18px;
		}
	}

	&.sticky-top {
		@include media-breakpoint-up(md) {
			position: absolute !important;
			right: 0;
			left: 0;
		}
	}

	&.bg-dark {
		background: $color-blue-3 !important;
		@include media-breakpoint-up(md) {
			background: none !important;
		}
	}

	.offcanvas {
		@include media-breakpoint-down(xl) {
			width: 90%;
			background: #e7f1ff;
			@include media-breakpoint-up(xsl) {
				max-width: 360px !important;
			}
		}
		@include media-breakpoint-up(xl) {
			position: absolute;
			top: 50px;
			right: 40px;
		}
		@include media-breakpoint-up(xxl) {
			position: absolute;
			top: 90px;
			right: 112px;
		}

		.navbar-nav {
			@include media-breakpoint-down(xl) {
				padding: 30px 0;
				font: 400 16px/150% $font-heading;
				.nav-item {
					padding: 5px 0;
				}
				.nav-link {
					padding: 5px 10px;
					color: $color-blue-3;
				}
				.nav-link.login-link {
					border-top: 1px solid #00000038;
					padding-top: 0.75rem;
				}
			}
			@include media-breakpoint-up(xl) {
				font: 500 15px $font-secondary;

				.nav-item + .nav-item {
					margin-left: 17px;
				}
				.nav-link {
					padding: 9.5px 10.25px;
					color: $color-white;
					transition: opacity $effect;
					&:hover,
					&:focus {
						//opacity: .8;
						color: $color-sky;
					}
				}
			}
		}
	}

	.btn {
		@include media-breakpoint-down(xl) {
			width: 150px;
			padding: 7px;
			border-width: 2px;
			border-radius: 4px;
			margin: 5px 0;
			display: block;
			font: 15px $font-default;
			line-height: normal;
			&.btn-outline-primary {
				--bs-btn-bg: transparent;
				--bs-btn-border-color: #{$color-orange};
				--bs-btn-color: #{$color-blue-1};
				--bs-btn-hover-bg: #{$color-orange};
				--bs-btn-hover-border-color: #{$color-orange};
				--bs-btn-hover-color: #{$color-blue-1};
				--bs-btn-active-bg: #dbb447;
				--bs-btn-active-border-color: #dbb447;
				--bs-btn-active-color: #{$color-blue-1};
				--bs-btn-disabled-bg: #{$color-orange};
				--bs-btn-disabled-border-color: #{$color-orange};
			}
		}
		@include media-breakpoint-up(xl) {
			min-width: 111px;
			padding: 7px;
			border-width: 2px;
			border-radius: 4px;
			font: 17px $font-default;
			line-height: normal;

			&.btn-primary {
				--bs-btn-bg: #bedced;
				--bs-btn-border-color: #bedced;
				--bs-btn-color: #{$color-blue-2};
				--bs-btn-hover-bg: #a4c3d5;
				--bs-btn-hover-border-color: #a4c3d5;
				--bs-btn-hover-color: #{$color-blue-1};
				--bs-btn-active-bg: #81a6bb;
				--bs-btn-active-border-color: #81a6bb;
				--bs-btn-active-color: #{$color-blue-1};
				--bs-btn-disabled-bg: #bedced;
				--bs-btn-disabled-border-color: #bedced;
			}
			&.btn-outline-primary {
				--bs-btn-bg: transparent;
				--bs-btn-border-color: #{$color-orange};
				--bs-btn-color: #{$color-white};
				--bs-btn-hover-bg: #{$color-orange};
				--bs-btn-hover-border-color: #{$color-orange};
				--bs-btn-hover-color: #{$color-blue-1};
				--bs-btn-active-bg: #dbb447;
				--bs-btn-active-border-color: #dbb447;
				--bs-btn-active-color: #{$color-blue-1};
				--bs-btn-disabled-bg: #{$color-orange};
				--bs-btn-disabled-border-color: #{$color-orange};
			}
		}
	}
}
