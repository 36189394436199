/* ==========================================================================
   TESTIMONIALS
   ========================================================================== */
.testimonials {
	padding: 5rem 0 5rem;
	position: relative;
	z-index: 2;
	@include media-breakpoint-up(md) {
		padding: 8rem 0 8rem;
	}
	@include media-breakpoint-up(xl) {
		// padding-top: 103px;
	}

	&::before {
		@include pseudo;
		position: absolute;
		top: 0;
		right: -1rem;
		bottom: 0;
		left: -1rem;
		z-index: -1;
		background: $color-blue;
		background-size: auto 100%;
		@include media-breakpoint-up(xss) {
			width: 100vw;
			right: auto;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&__header {
		margin-bottom: 6px;
		@include media-breakpoint-up(md) {
			margin-bottom: 32px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 58px;
		}

		h3 {
			margin-bottom: 0;
			//font-size: 20px;
			text-align: center;
			@include media-breakpoint-up(md) {
				//	font-size: 22px;
			}
			@include media-breakpoint-up(lg) {
				//font-size: 26px;
			}
			@include media-breakpoint-up(xl) {
				//font-size: 30px;
			}
			@include media-breakpoint-up(xl) {
				//font-size: 32px;
			}
		}
	}

	.swiper {
		height: 100%;
		margin: 0 -16px;
		position: relative;
		@include media-breakpoint-up(sm) {
			width: 100vw;
			margin-right: 0;
			left: 50%;
			margin-left: -50vw;
		}
		@include media-breakpoint-up(md) {
			padding-bottom: 30px;
		}
		@include media-breakpoint-up(xl) {
			padding-bottom: 152px;
		}

		&-wrapper {
			display: inline-flex;
		}

		&-slide {
			width: 300px;
			min-height: 100%;
			padding: 10px 4px 10px 16px;
			display: flex;
			cursor: default;
			@include media-breakpoint-up(sm) {
				width: 350px;
			}
			@include media-breakpoint-up(md) {
				width: 400px;
				padding-right: 10px;
				padding-left: 10px;
			}
			@include media-breakpoint-up(lg) {
				width: 440px;
			}
			@include media-breakpoint-up(xl) {
				width: 540px;
				padding-right: 15px;
				padding-left: 15px;
			}
			// @include media-breakpoint-up(xxl) {
			//   width: 730px;
			// }
		}

		&-button-prev,
		&-button-next {
			width: 40px;
			height: 22px;
			border-radius: 30px;
			top: auto;
			bottom: 0;
			display: none;
			background: rgba(#1f3a68, 0.5);
			&::after {
				display: block;
				color: white;
				font-size: 12px;
				line-height: 22px;
				text-align: center;
			}
			@include media-breakpoint-up(md) {
				display: block;
			}
			@include media-breakpoint-up(xl) {
				width: 80px;
				height: 44px;
				bottom: 35px;
				&::after {
					font-size: 22px;
					line-height: 44px;
				}
			}
		}
		&-button-prev {
			content: "U+003C";
			margin-left: -50px;
			left: 50%;
			right: auto;
			@include media-breakpoint-up(xl) {
				margin-left: -100px;
			}
		}
		&-button-next {
			content: "U+003E";
			margin-right: -50px;
			left: auto;
			right: 50%;
			@include media-breakpoint-up(xl) {
				margin-right: -100px;
			}
		}
	}

	&__item {
		flex: 1;
		width: 280px;
		padding: 10px 8px 4px;
		margin-right: 20px;
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.8) 0%,
			rgba(255, 255, 255, 0.531173) 99.98%,
			rgba(255, 255, 255, 0.416) 99.99%,
			rgba(255, 255, 255, 0) 100%
		);
		//border: 1px solid #e4e4e4;
		border-radius: 10px;
		//box-shadow: 0 4px 4px rgba(44, 81, 142, .2);
		flex-shrink: 0;
		//font: 10px/23px $font-heading;
		text-align: center;
		position: relative;
		@include media-breakpoint-up(sm) {
			width: 100%;
			font-size: 12px;
		}
		@include media-breakpoint-up(md) {
			padding: 44px 16px 16px;
			font-size: 14px;
			line-height: 150%;
			border-width: 3px;
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 0.8) 0%,
				rgba(255, 255, 255, 0.531173) 99.98%,
				rgba(255, 255, 255, 0.416) 99.99%,
				rgba(255, 255, 255, 0) 100%
			);
			box-shadow: 0 4px 4px rgba(black, 0.25);
		}
		@include media-breakpoint-up(lg) {
			font-size: 16px;
		}
		// @include media-breakpoint-up(xl) {
		//   padding-right: 30px;
		//   padding-left: 30px;
		//   padding-bottom: 25px;
		//   font-size: 20px;
		// }
		@include media-breakpoint-up(xxl) {
			padding-top: 90px;
			//   font-size: 24px;
		}

		&::before {
			@include media-breakpoint-up(md) {
				@include pseudo;
				content: "❝";
				width: 30px;
				height: 25px;
				background-size: contain;
				margin: 0 auto;
				position: absolute;
				top: 12px;
				left: 0;
				right: 4px;
				font-size: 54px;
				padding-top: 14px;
			}
			@include media-breakpoint-up(xxl) {
				font-size: 100px;
				padding-top: 35px;
				width: 50px;
				height: 50px;
			}
		}

		blockquote {
			height: 100%;
			margin: 0;
			display: flex;
			flex-direction: column;
		}

		p {
			margin-bottom: 5px;
			&:last-of-type {
				margin-bottom: 0;
			}
			@include media-breakpoint-up(md) {
				margin-bottom: 15px;
			}
			@include media-breakpoint-up(xxl) {
				margin-bottom: 36px;
			}
		}

		footer {
			padding-top: 18px;
			margin: auto 0 0 0;
			display: flex;
			align-items: center;
			justify-content: flex-start;

			p {
				padding-top: 14px;
				margin: 0;
				font: 600 16px/22px $font-secondary;
				color: $color-dark;
				text-align: left;
				letter-spacing: 0.01em;
				flex-grow: 1;
				@include media-breakpoint-up(xl) {
					font-size: 20px;
					flex-grow: 0;
					text-align: center;
				}

				small {
					display: block;
					font: 400 13px/24px $font-default;
					color: $color-grey;
					letter-spacing: 0;
					@include media-breakpoint-up(md) {
						font-size: 14px;
						line-height: 125%;
					}
					@include media-breakpoint-up(xl) {
						margin-top: 18px;
						font-size: 18px;
					}
					@include media-breakpoint-down(xl) {
						br {
							display: none;
						}
					}
				}
			}
		}
	}

	&__photo {
		width: 66px;
		height: 72px;
		margin: 0 14px 0 -2px;
		border-radius: 30px;
		display: block;
		object-fit: cover;
		user-select: none;
		@include media-breakpoint-up(xl) {
			width: 126px;
			height: 120px;
		}
	}

	&__reference {
		width: 100%;
	}

	&__logo {
		display: none;
		@include user-select;
		@include media-breakpoint-up(md) {
			width: auto;
			height: auto;
			max-width: 80px;
			max-height: 50px;
			display: block;
			//margin-left: auto;
		}
		@include media-breakpoint-up(lg) {
			max-width: 120px;
			max-height: 80px;
		}
		@include media-breakpoint-up(xxl) {
			max-width: 158px;
			max-height: 100px;
			margin-right: 7px;
		}
	}
}
