/* ==========================================================================
   ARTICLES
   ========================================================================== */
.articles {
	max-width: 1288px;
	//margin: -40px auto 0;
	margin: 0 auto;
	padding-top: 30px;
	padding-bottom: 25px;
	position: relative;
	z-index: 1;
	@include media-breakpoint-up(md) {
		//margin-top: -90px;
		padding-top: 66px;
		padding-bottom: 38px;
		margin-bottom: 32px;
	}

	&__head {
		h2 {
			@include media-breakpoint-up(md) {
				margin-bottom: 22px;
				//font-size: 56px;
			}
		}

		h3 {
			@include media-breakpoint-up(md) {
				margin-top: 26px;
				margin-bottom: 22px;
			}
		}
	}

	.card {
		border-radius: 8px;
		box-shadow: 0 2px 2px #0c131e36;
		margin-bottom: 30px;
		border-color: #bcdaec;
		@include media-breakpoint-up(md) {
			height: calc(100% - 24px);
			margin-bottom: 24px;
			font-size: 16px;
		}

		.col-xl-5 {
			@include media-breakpoint-up(xxl) {
				width: 40%;
			}
		}
		.col-xl-7 {
			@include media-breakpoint-up(xxl) {
				width: 60%;
			}
		}

		&-link {
			--bs-gutter-x: 0;
			text-decoration: none;
			width: 100%;
			@include media-breakpoint-up(md) {
				height: 100%;
				min-height: 180px;
			}
		}

		&-img {
			&-top,
			&-left,
			&-bottom,
			,
			& -right {
				@include media-breakpoint-down(md) {
					width: 100%;
					height: 100%;
					object-fit: cover;
					display: block;
					@include user-select;
				}
			}

			&-left,
			&-right {
				@include media-breakpoint-up(md) {
					width: 100%;
					height: 100%;
					object-fit: cover;
					display: block;
					@include user-select;
				}
			}

			&-left {
				@include media-breakpoint-up(md) {
					border-radius: 8px 0 0 8px;
				}
			}
			&-right {
				@include media-breakpoint-up(md) {
					border-radius: 0 8px 8px 0;
				}
			}
			&-top {
				@include media-breakpoint-up(md) {
					border-radius: 8px 8px 0 0;
				}
			}
			&-bottom {
				@include media-breakpoint-up(md) {
					border-radius: 0 0 8px 8px;
				}
			}
		}

		&-body {
			@include media-breakpoint-up(xl) {
				padding-bottom: 0;
			}
		}

		&-title {
			line-height: 1.25em;
			text-align: left;
			@include media-breakpoint-up(md) {
				padding-bottom: 6px;
				margin-bottom: 2px;
				border-bottom: 1px solid $color-gray;
			}
		}

		&-footer {
			background: #f7f7f7;
			border-top: 1px solid #0000000a;
			color: $color-black;
		}

		&--big {
			@include media-breakpoint-up(md) {
				//height: calc(100% - 24px);
			}

			.card {
				&-link {
					width: 100%;
					@include media-breakpoint-up(md) {
						height: 100%;
						display: flex;
					}
				}

				&-img-top {
					@include media-breakpoint-up(md) {
						//flex-grow: 0;
					}
				}

				&-body {
					@include media-breakpoint-up(md) {
						//flex-grow: 1;
					}
				}

				&-title {
					line-height: 1.25em;
					padding-bottom: 0;
					margin-bottom: 7px;
					border-bottom: none;
				}

				&-footer {
					@include media-breakpoint-up(md) {
						flex-grow: 0;
						font-size: 13px;
						position: absolute;
						bottom: 0;
						width: 100%;
					}
				}
			}
		}

		&--small {
			@include media-breakpoint-up(md) {
				height: calc(33.3% - 24px);
			}

			.card {
				&-link {
					@include media-breakpoint-up(md) {
						min-height: 201px;
					}
				}

				&-body {
					@include media-breakpoint-up(md) {
						height: 100%;
						padding-top: 10px;
						padding-bottom: 30px;
						height: 240px;
					}
					@include media-breakpoint-up(xxl) {
						padding-bottom: 25px;
						height: 240px;
					}
				}

				&-title {
					line-height: 1.25em;
					@include media-breakpoint-up(md) {
						margin-bottom: 2px;
					}
				}

				&-text {
					max-height: 180px;
					overflow: clip;
					max-height: 220px;
					@include media-breakpoint-up(md) {
						color: $color-grey;
						letter-spacing: 0.02em;
						color: $gray-700;
						font-size: 13px;
						line-height: 20px;
						max-height: 220px;
						height: auto;
					}
				}

				&-footer {
					@include media-breakpoint-up(md) {
						letter-spacing: 0.02em;
						background-color: #f7f7f7;
						border: none;
						padding-top: 0.5rem;
						padding-bottom: 0.5rem;
						font-size: 13px;
						position: absolute;
						bottom: 0;
					}
				}
			}
		}
	}

	.top-articles-container {
		height: 100%;
		position: relative;
	}

	.card.articles-all {
		background-color: #f6fcff;
		border-radius: 8px;
	}

	&--blue {
		&::after {
			@include pseudo;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
			background: $color-blue;
			@include media-breakpoint-up(xss) {
				width: 100vw;
				right: auto;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		> .row {
			position: relative;
			z-index: 3;
		}
	}

	&--open {
		@include media-breakpoint-up(xxl) {
			max-width: none;
			margin-left: -25px;
			margin-right: -25px;
		}

		&::after {
			@include pseudo;
			height: 860px;
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			z-index: 1;
			background: $color-blue;
			@include media-breakpoint-up(xss) {
				width: 100vw;
				right: auto;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		> .row {
			position: relative;
			z-index: 3;
		}

		.articles__head {
			h3 {
				@include media-breakpoint-up(xxl) {
					margin-top: 60px;
				}
			}
		}

		.card {
			&--big {
				@include media-breakpoint-up(xxl) {
					border-radius: 40px;
					font-size: 24px;
					line-height: 150%;
				}

				.card-body {
					@include media-breakpoint-up(xxl) {
						padding-bottom: 0;
						padding-left: 48px;
						padding-right: 48px;
					}
				}

				.card-title {
					line-height: 1.25em;
					@include media-breakpoint-up(xxl) {
						font-size: 42px;
						line-height: 120%;
					}
				}

				.card-img-top {
					@include media-breakpoint-up(xxl) {
						border-radius: 40px 40px 0 0;
					}
				}

				.card-text:not(:last-child) {
					@include media-breakpoint-up(xxl) {
						margin-bottom: 36px;
					}
				}
			}
		}
	}

	.card-text.main-article {
		max-height: 262px;
		overflow: clip;

		// &::after {
		// 	@include pseudo;
		// 	vertical-align: middle;
		// 	width: 100%;
		// 	height: 15%;
		// 	left: 0;
		// 	display: inline-block;
		// 	position: absolute;
		// 	bottom: 37px;
		// 	overflow: hidden;
		// 	background: rgb(255, 255, 255);
		// 	background: linear-gradient(0deg, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0) 100%);
		// }
	}

	#article-container-main {
		display: relative;
		height: 100%;

		.card-body {
			@include media-breakpoint-up(sm) {
				height: 369px;
			}
		}
	}

	.article-footer {
		width: inherit;
		&::before {
			@include pseudo;
			vertical-align: middle;
			min-width: 100%; //in IE equivalent to
			width: -moz-available; /* Mozilla */
			width: -webkit-fill-available; /* Webkit */
			width: -o-available; /* OPera */
			left: 0;
			height: 5rem;
			display: inline-block;
			position: absolute;
			bottom: 40px;
			overflow: hidden;
			background: rgb(255, 255, 255);
			background: linear-gradient(0deg, rgb(255, 255, 255) 15%, rgba(255, 255, 255, 0) 100%);
		}
	}

	.article-container-link {
		overflow: clip;
	}

	.browse-article {
		max-height: 250px;
		overflow: clip;

		.browse-footer {
			height: 0px;
			width: inherit;
			position: absolute;
			bottom: 0;

			&::after {
				@include pseudo;
				background: linear-gradient(0deg, #fff 15%, #fff0 100%);
				width: 100%;
				height: 5rem;
				display: inline-block;
				position: absolute;
				bottom: 0;
				overflow: hidden;
				left: 0;
			}
		}

		img {
			object-fit: cover;
			height: 250px;
			object-position: 0% 50%;
			width: 100%;
		}
	}
}
