/* ==========================================================================
   SERVICE
   ========================================================================== */
.service {
	padding: 5rem 0 5rem;
	position: relative;
	@include media-breakpoint-up(md) {
		padding: 8rem 0 8rem;
	}
	// @include media-breakpoint-up(lg) {
	//   padding: 90px 0;
	// }
	// @include media-breakpoint-up(xl) {
	//   padding: 156px 0 159px;
	// }

	&::after {
		@include pseudo;
		position: absolute;
		top: 0;
		right: -1rem;
		bottom: 0;
		left: -1rem;
		z-index: 0;
		background: $color-blue;
		@include media-breakpoint-up(xss) {
			width: calc(100vw + 2px);
			right: auto;
			left: calc(50% - 1px);
			transform: translateX(-50%);
		}
		@include media-breakpoint-up(md) {
			//  background-image: url('#{$img}/bg/decal-13.webp'), url('#{$img}/bg/decal-14.webp'), url('#{$img}/bg/decal-15.webp'), url('#{$img}/bg/decal-16.webp');
			background-position:
				left top,
				right top,
				left bottom,
				right bottom;
			background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
			background-size:
				auto 422px,
				auto 210px,
				auto 435px,
				auto 183px;
		}
		@include media-breakpoint-up(xl) {
			background-size:
				auto 704px,
				auto 350px,
				auto 726px,
				auto 305px;
		}
	}

	> .row {
		position: relative;
		z-index: 1;
	}

	&__content {
		margin: 0 -4px;
		position: relative;
		@include media-breakpoint-up(md) {
			padding: 30px 40px;
			border-radius: 20px;
			margin: 0;
			background: linear-gradient(180deg, #e5f2fe 0%, rgba(246, 250, 252, 0.542484) 99.99%, rgba(236, 244, 248, 0) 100%);
			box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

			// &::before, &::after {
			//   @include pseudo;
			//   width: 114px;
			//   height: 116px;
			//   position: absolute;
			//   z-index: -1;
			//   background: url('#{$img}/square.webp') no-repeat center center;
			//   background-size: contain;
			//   @include media-breakpoint-up(xl) {
			//     width: 228px;
			//     height: 232px
			//   }
			// }
			// &::before {
			//   top: -15px;
			//   left: -23px;
			// }
			// &::after {
			//   right: -17px;
			//   bottom: -22px;
			// }
		}
		@include media-breakpoint-up(xl) {
			padding-right: 60px;
			padding-bottom: 60px;
			padding-left: 70px;
		}
		@include media-breakpoint-up(xxl) {
			padding: 31px 113px 71px 102px;
			margin-right: 22px;
			margin-left: 22px;
		}
	}

	&__heading {
		padding: 15px 0 15px 6px;
		margin-bottom: 37px;
		//font-size: 22px;
		line-height: 17px;
		text-align: center;
		position: relative;
		z-index: 1;
		cursor: default;
		@include media-breakpoint-up(md) {
			padding: 0;
			line-height: 29px;
		}
		@include media-breakpoint-up(lg) {
			//font-size: 26px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 54px;
			//font-size: 36px;
			line-height: 39px;
		}

		// &::after {
		//   @include pseudo;
		//   width: 286px;
		//   height: 64px;
		//   margin-left: -20px;
		//   position: absolute;
		//   top: 50%;
		//   left: 50%;
		//   z-index: -1;
		//   transform: translate3d(-50%, -50%, 0);
		//   background-size: contain;
		//   @include media-breakpoint-up(md) {
		//     width: 350px;
		//     height: 70px;
		//     margin-top: -3px;
		//     margin-left: -35px;
		//   }
		//   @include media-breakpoint-up(xl) {
		//     width: 643px;
		//     height: 123px;
		//     margin-left: -50px;
		//   }
		// }
	}

	.nav-tabs {
		width: 100%;
		border-bottom: 1px solid #32569152;
		//margin: 0 0 20px;
		//font-size: 10px;
		line-height: 150%;
		justify-content: center;
		@include media-breakpoint-up(xsl) {
			font-size: 12px;
		}
		@include media-breakpoint-up(md) {
			//   border: none;
			//   margin-bottom: 30px;
			font-size: 18px;
			//   font-weight: normal;
			justify-content: space-evenly;
		}
		@include media-breakpoint-up(xl) {
			//   padding-left: 30px;
			//   margin: 0 41px 50px;
			//font-size: 24px;
			justify-content: space-evenly;
		}

		.nav-link {
			width: 33.33%;
			font-size: large;
			border-color: transparent;
			padding: 0.5rem;

			// @include media-breakpoint-up(md) {
			//   padding: 3px;
			//   border: none;
			//   border-bottom: 2px solid transparent;
			//   color: $color-blue-2;
			//   background: none;
			// }

			&.active {
				border-top-color: #32569152;
				border-right-color: #32569152;
				border-left-color: #32569152;
				color: $color-blue-3;
				padding: 0 1rem;
				// @include media-breakpoint-up(md) {
				//   border-bottom-color: $color-blue-3;
				//   font-weight: 600;
				//   color: $color-blue-2;
				// }
			}
		}
	}

	&__key {
		padding: 1.5rem;
		margin: 0 -2px 24px;
		font: 12px/175% $font-heading;
		letter-spacing: 0.05em;
		text-align: center;
		background: rgba(253, 238, 188, 0.6);
		cursor: default;
		@include media-breakpoint-up(md) {
			margin-bottom: 18px;
			font-size: 14px;
			background: rgba(253, 238, 188, 0.6);
		}
		@include media-breakpoint-up(xl) {
			margin-right: 10px;
			margin-left: 10px;
			font-size: 18px;
			line-height: 150%;
		}
		@include media-breakpoint-up(xl) {
			min-height: 77px;
			margin-right: 20px;
			margin-left: 20px;
			font-size: 20px;
		}

		strong {
			font-weight: 600;
			@include media-breakpoint-down(md) {
				display: block;
			}
		}
	}

	&__title {
		margin: 0 0 12px;
		font: 600 20px/150% $font-heading;
		letter-spacing: 1px;
		text-align: center;
		@include media-breakpoint-up(md) {
			padding: 14px 0 0;
			border-top: 1px solid rgba(#9ebbe0, 0.8);
			margin-bottom: 26px;
			@include media-breakpoint-down(xl) {
				margin-bottom: 18px;
				font-size: 18px;
			}
		}
	}

	&__solutions {
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;
		font: 14px/150% $font-heading;
		letter-spacing: 1px;
		text-align: center;
		&::after {
			@include clearfix;
		}
		@include media-breakpoint-up(md) {
			text-align: left;
		}
		@include media-breakpoint-up(xl) {
			font-size: 20px;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 30px;
		}

		li {
			padding-right: 1rem;
			@include media-breakpoint-up(md) {
				width: 50%;
				padding-left: 26px;
				float: left;
				&:nth-child(2n-1) {
					clear: left;
				}
			}
			@include media-breakpoint-up(xl) {
				min-height: 60px;
			}

			& + li {
				@include media-breakpoint-down(md) {
					margin-top: 20px;
				}
				& + li {
					@include media-breakpoint-up(md) {
						margin-top: 20px;
					}
					@include media-breakpoint-up(xl) {
						margin-top: 31px;
					}
				}
			}
		}

		span {
			position: relative;

			&::before {
				@include pseudo;
				width: 0;
				height: 0;
				top: 0;
				left: 0;
				border-radius: 50%;
				position: absolute;
				background: rgba($color-blue-2, 0.2);
				@include media-breakpoint-up(md) {
					width: 12px;
					height: 12px;
					top: 3px;
					left: -1rem;
				}
				@include media-breakpoint-up(xl) {
					width: 14px;
					height: 14px;
					top: 5px;
					left: -25px;
				}
			}
		}
	}

	.tab-content {
		background-color: #fff;
		padding: 2rem 2rem 3rem;
		border-radius: 0 0 4px 4px;
		border: 1px solid #32569152;
		border-top-color: transparent;
	}
}
