/* ==========================================================================
   BLOCK
   ========================================================================== */
.block {
	padding-top: 40px;
	padding-bottom: 40px;
	@include media-breakpoint-up(md) {
		padding-top: 54px;
		padding-bottom: 60px;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 90px;
	}

	.rounded {
		border-radius: 30px !important;
		@include user-select;
		@include media-breakpoint-up(md) {
			border-radius: 60px !important;
		}

		&.shadowed {
			box-shadow: -5px 6px 10px rgba($color-blue-2, 0.2);
		}

		&.bordered {
			border: 1px solid $color-black;
		}
	}

	&--white {
		position: relative;
		font-family: $font-heading;
		line-height: 2em;
		letter-spacing: 0.1em;
		@include media-breakpoint-up(md) {
			font-size: 16px;
		}
		@include media-breakpoint-up(xl) {
			font-size: 18px;
		}
		@include media-breakpoint-up(xxl) {
			font-size: 20px;
		}

		&::after {
			@include pseudo;
			display: none;
			position: absolute;
			top: 0;
			right: -1rem;
			bottom: 0;
			left: -1rem;
			z-index: 0;
			background-image: url("#{$img}/bg/decal-5.webp"), url("#{$img}/bg/decal-6.webp"), url("#{$img}/bg/decal-7.webp"),
				url("#{$img}/bg/decal-8.webp");
			background-position:
				left top,
				right top,
				left bottom,
				right bottom;
			background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
			background-size:
				auto 840px,
				auto 160px,
				auto 180px,
				auto 250px;
			@include media-breakpoint-up(xss) {
				width: calc(100vw + 2px);
				right: auto;
				left: calc(50% - 1px);
				transform: translateX(-50%);
			}
			@include media-breakpoint-up(md) {
				display: block;
			}
			@include media-breakpoint-up(xl) {
				background-size:
					auto 1200px,
					auto 200px,
					auto 255px,
					auto 355px;
			}
		}

		.row {
			position: relative;
			z-index: 1;
		}

		p {
			margin-bottom: 2em;
			&:first-child::first-letter {
				font-size: 1.4em;
			}
		}

		strong {
			font-weight: 600;
		}

		.rounded {
			@include media-breakpoint-down(md) {
				@include media-breakpoint-up(xsm) {
					max-width: 320px;
				}
			}
			@include media-breakpoint-up(xl) {
				border-radius: 100px !important;
			}
		}
	}

	&--blue {
		position: relative;

		&::after {
			@include pseudo;
			position: absolute;
			top: 0;
			right: -1rem;
			bottom: 0;
			left: -1rem;
			z-index: 0;
			background-color: #bcdaec;
			@include media-breakpoint-up(xss) {
				width: calc(100vw + 2px);
				right: auto;
				left: calc(50% - 1px);
				transform: translateX(-50%);
			}
			@include media-breakpoint-up(md) {
				background-image: url("#{$img}/bg/decal-9.webp"), url("#{$img}/bg/decal-10.webp"), url("#{$img}/bg/decal-11.webp"),
					url("#{$img}/bg/decal-12.webp");
				background-position:
					left top,
					right top,
					left bottom,
					right bottom;
				background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
				background-size:
					auto 300px,
					auto 150px,
					auto 610px,
					auto 360px;
			}
			@include media-breakpoint-up(xl) {
				background-size:
					auto 430px,
					auto 207px,
					auto 815px,
					auto 482px;
			}
		}

		.row {
			position: relative;
			z-index: 1;
		}
	}

	&--bluefill {
		position: relative;
		min-height: calc(100vh - (320px + 255px));
		&::after {
			@include pseudo;
			position: absolute;
			top: 0;
			right: -1rem;
			bottom: 0;
			left: -1rem;
			z-index: 0;
			background-color: #bcdaec;
			@include media-breakpoint-up(xss) {
				width: calc(100vw + 2px);
				right: auto;
				left: calc(50% - 1px);
				transform: translateX(-50%);
			}
			@include media-breakpoint-up(md) {
				background-image: url("#{$img}/bg/decal-9.webp"), url("#{$img}/bg/decal-10.webp"), url("#{$img}/bg/decal-11.webp"),
					url("#{$img}/bg/decal-12.webp");
				background-position:
					left top,
					right top,
					left bottom,
					right bottom;
				background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
				background-size:
					auto 300px,
					auto 150px,
					auto 610px,
					auto 360px;
			}
			@include media-breakpoint-up(xl) {
				background-size:
					auto 430px,
					auto 207px,
					auto 815px,
					auto 482px;
			}
		}

		.row {
			position: relative;
			z-index: 1;
		}
	}

	&--pricing {
		position: relative;

		&::after {
			@include pseudo;
			position: absolute;
			top: 0;
			right: -1rem;
			bottom: 0;
			left: -1rem;
			z-index: 0;
			background-color: #f3f6f9;
			@include media-breakpoint-up(xss) {
				width: calc(100vw + 2px);
				right: auto;
				left: calc(50% - 1px);
				transform: translateX(-50%);
			}
			@include media-breakpoint-up(md) {
				background-image: url("#{$img}/bg/decal-1.webp"), url("#{$img}/bg/decal-2.webp"), url("#{$img}/bg/decal-3.webp"),
					url("#{$img}/bg/decal-4.webp");
				background-position:
					left top,
					right top,
					left bottom,
					right bottom;
				background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
				background-size:
					auto 140px,
					auto 518px,
					auto 52px,
					auto 450px;
			}
			@include media-breakpoint-up(xl) {
				background-size:
					auto 187px,
					auto 690px,
					auto 70px,
					auto 600px;
			}
		}

		.row {
			position: relative;
			z-index: 1;
		}
	}

	&--latest {
		padding-top: 0;
		padding-bottom: 0;
	}
}
