/* ==========================================================================
   AWARDS
   ========================================================================== */
.awards {
	padding: 5rem 0 5rem;
	text-align: center;
	position: relative;

	@include media-breakpoint-up(md) {
		padding: 8rem 0 8rem;
		display: block;
	}

	&::after {
		@include pseudo;
		position: absolute;
		top: 0;
		right: -1rem;
		bottom: 0;
		left: -1rem;
		z-index: 0;
		background: white;
		@include media-breakpoint-up(xss) {
			width: calc(100vw + 2px);
			right: auto;
			left: calc(50% - 1px);
			transform: translateX(-50%);
		}
		@include media-breakpoint-up(md) {
			background: #f4f6f9;
		}
	}

	> .row {
		position: relative;
		z-index: 1;
	}

	h3 {
		margin-bottom: 12px;
		@include media-breakpoint-up(lg) {
			margin-bottom: 45px;
			font-size: 32px;
			// font-weight: 500;
		}
	}

	figure {
		padding: 2rem;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: auto;
			height: 60px;
			margin: 0 6px;
			display: block;
			@include media-breakpoint-up(lg) {
				height: 80px;
				margin: 0 8px;
			}
			@include media-breakpoint-up(xxl) {
				height: 100px;
				margin: 0 12px;
			}
		}
	}
}
