/* ==========================================================================
   ARTICLES
   ========================================================================== */
.single-article {
	height: 100%;
	margin-bottom: 0;

	.inner-article-image {
		width: 100%;
		max-height: 420px;
		object-fit: cover;
		margin: 2rem 0;
	}

	.col-md-4.col-xl-5 {
		padding-left: 0;
		padding-right: 0;
	}

	.single-article-body {
		height: 100%;
		padding: 2rem 2rem 4rem;
	}
}

.single-sub-article {
	max-height: 250px;
	overflow: clip;

	.single-sub-article-footer {
		&::before {
			@include pseudo;
			vertical-align: middle;
			min-width: 100%; //in IE equivalent to
			width: -moz-available; /* Mozilla */
			width: -webkit-fill-available; /* Webkit */
			width: -o-available; /* OPera */
			right: 0;
			height: 5rem;
			display: inline-block;
			position: absolute;
			bottom: 0;
			overflow: hidden;
			background: rgb(255, 255, 255);
			background: linear-gradient(0deg, rgb(255, 255, 255) 15%, rgba(255, 255, 255, 0) 100%);
		}
		@include media-breakpoint-up(md) {
			width: inherit;
			right: 0;

			&::before {
				@include pseudo;
				vertical-align: middle;
				min-width: 100%; //in IE equivalent to
				width: -moz-available; /* Mozilla */
				width: -webkit-fill-available; /* Webkit */
				width: -o-available; /* OPera */
				right: 0;
				height: 5rem;
				display: inline-block;
				position: absolute;
				bottom: 40px;
				overflow: hidden;
				background: rgb(255, 255, 255);
				background: linear-gradient(0deg, rgb(255, 255, 255) 15%, rgba(255, 255, 255, 0) 100%);
			}
		}
	}

	img {
		object-fit: cover;
		height: 250px;
		object-position: 0% 50%;
		width: 100%;
	}
}
