/* ==========================================================================
   INDUSTRY
   ========================================================================== */
.industry {
	padding: 5rem 0 5rem;
	position: relative;
	text-align: center;
	@include media-breakpoint-up(md) {
		padding: 8rem 0 8rem;
	}
	@include media-breakpoint-up(xl) {
		//  padding: 5rem 0 5rem;
	}
	@include media-breakpoint-up(xxl) {
		// padding: 210px 0;
	}

	&::after {
		@include pseudo;
		position: absolute;
		top: 0;
		right: -1rem;
		bottom: 0;
		left: -1rem;
		z-index: 0;
		background: white;
		@include media-breakpoint-up(xss) {
			width: calc(100vw + 2px);
			right: auto;
			left: calc(50% - 1px);
			transform: translateX(-50%);
		}
		@include media-breakpoint-up(md) {
			background: #f4f6f9;
		}
	}

	> .row {
		position: relative;
		z-index: 1;
		@include media-breakpoint-down(md) {
			padding-right: 9px;
			padding-left: 9px;
		}
		@include media-breakpoint-up(xl) {
			max-width: 1270px;
			margin-right: auto;
			margin-left: auto;
		}
	}

	&__header {
		margin: -2px -9px 20px;
		font: 14px/22px $font-heading;
		text-align: center;
		@include media-breakpoint-up(md) {
			margin: 0 0 42px;
			font-size: 16px;
			line-height: 22px;
		}
		@include media-breakpoint-up(md) {
			font-size: 18px;
		}
		@include media-breakpoint-up(xl) {
			font-size: 20px;
		}

		h2 {
			margin-bottom: 10px;
			///font-size: 22px;
			line-height: 19px;
			@include media-breakpoint-up(md) {
				margin-bottom: 12px;
				//font-size: 22px;
				line-height: 29px;
			}
			@include media-breakpoint-up(lg) {
				//-size: 26px;
				line-height: 36px;
			}
			@include media-breakpoint-up(xl) {
				//font-size: 32px;
				line-height: 49px;
			}
		}

		p {
			margin: 0;
		}
	}

	&__content {
		display: flex;
	}

	&__entry {
		height: 200px;
		padding: 10px 6px 12px;
		border-radius: 5px;
		margin: 0 -10px 10px;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		//font-size: 18px;
		font-weight: bold;
		color: $color-blue-2;
		background: linear-gradient(to left, #bcdaec 18%, white 100%);
		box-shadow: 0 2px 2px rgba(black, 0.25);
		@include media-breakpoint-up(md) {
			height: 210px;
			padding-right: 10px;
			padding-left: 10px;
			margin: 0 0 20px;
			box-shadow: 0 2px 2px rgba(black, 0.25);
			background: linear-gradient(
				180deg,
				#bcdaec 0%,
				rgba(188, 218, 236, 0.58) 46.5%,
				rgba(188, 218, 236, 0.4) 99.99%,
				rgba(188, 218, 236, 0) 100%
			);
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 36px;
		}

		// &.active {
		// 	.industry__list {
		// 		max-height: 200px;
		// 	}
		// }
		// @include media-breakpoint-up(xl) {
		// 	@media (hover: hover) {
		// 		&:hover {
		// 			.industry__list {
		// 				max-height: 200px;
		// 			}
		// 		}
		// 	}
		// }
	}

	&__title {
		margin: 0 0 6px;
		font: 600 18px $font-heading;
		@include media-breakpoint-down(xsxs) {
			font-size: 16px;
		}
		@include media-breakpoint-up(md) {
			font-size: 20px;
		}
		@include media-breakpoint-up(xl) {
			font-size: 30px;
		}
	}

	&__list {
		max-height: 200px;
		padding-left: 18px;
		margin: 0;
		font-weight: 400;
		//overflow: hidden;
		text-align: left;
		//transition: max-height $effect;

		li + li {
			margin-top: 4px;
			@include media-breakpoint-up(md) {
				margin-top: 2px;
			}
			@include media-breakpoint-up(xl) {
				margin-top: 0;
			}
		}
	}
}
