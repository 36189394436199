* {
	&,
	:before,
	:after {
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(black, 0);
	}
}

:root {
	--bs-font-sans-serif: #{$font-default};
	--bs-body-font-size: 16px;
	--bs-body-line-height: 150%;
	--bs-heading-color: inherit;
	--bs-body-color: #{$color-blue-2};
	--bs-body-color-rgb: 44, 81, 142;
	--bs-link-color: #{$color-blue-2};
	--bs-link-color-rgb: 44, 81, 142;
	--bs-link-hover-color: #{$color-blue-2};
	--bs-link-hover-color-rgb: 44, 81, 142;
	@include media-breakpoint-down(md) {
		--bs-body-font-size: 14px;
	}
}

html {
	width: 100%;
	height: 100%;
	background-color: var(--bs-body-bg);
}

body {
	width: 100%;
	min-height: 100%;
	min-width: $min-width;
	position: relative;
	display: flex;
	flex-direction: column;
	-webkit-font-smoothing: antialiased;
}

a {
	//text-decoration: underline;
	&:hover,
	&:focus {
		text-decoration: none;
	}
}

/** Paragraphs **/
.p1-r,
.p1-b {
	font-size: 16px;
}
.p2-r,
.p2-b {
	font-size: 14px;
}
.p3-r,
.p3-b {
	font-size: 12px;
}
.p1-r,
.p1-b,
.p2-r,
.p2-b,
.p3-r,
.p3-b {
	line-height: 150%;
}
.p1-b,
.p2-b,
.p3-b {
	font-weight: bold;
}

/** Headings **/
// .h1,
// .h2,
// .h3,
// .h4,
// .h5,
// .h6,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
// 	font-family: $font-heading;
// 	font-weight: 600;
// 	line-height: 125%;
// }
// .h1,
// h1 {
// 	font-size: 40px;
// }
// .h2,
// h2 {
// 	font-size: 32px;
// }
// .h3,
// h3 {
// 	font-size: 24px;
// }
// .h4,
// h4 {
// 	font-size: 18px;
// }
// .h5,
// h5 {
// 	font-size: 14px;
// }

/** Grid **/
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
	@include media-breakpoint-up(xxxl) {
		max-width: 1452px;
	}
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl,
.row {
	--bs-gutter-x: 2rem;
}

.rounded {
	border-radius: var(--bs-border-radius-xl) !important;
}

/** Card **/
.card {
	// 	--bs-card-border-width: 1px;
	// 	--bs-card-border-color: #d2d2d2;
	// 	--bs-card-border-radius: 0;
	// 	--bs-card-inner-border-radius: 0;
	--bs-card-title-color: #{$color-black};
	--bs-card-color: #{$color-black};
	font-family: $font-tertirary;
	font-size: 14px;
	// 	line-height: 150%;
	// 	box-shadow: 4px 4px 8px rgba($color-black, 0.25);

	// 	&-img,
	// 	&-img-top {
	// 		@include user-select;
	// 	}

	// 	&-title {
	// 		margin-bottom: 7px;
	// 		font: bold 20px/120% $font-tertirary;
	// 		text-align: center;
	// 	}
}

/** Accordion **/
.accordion {
	--bs-accordion-border-width: 0;
	--bs-accordion-border-radius: 0.5rem;
	--bs-accordion-bg: #f8f9fe;
	--bs-accordion-active-color: #0c63e4;
	--bs-accordion-active-bg: #e7f1ff;

	&-item {
		border-radius: var(--bs-accordion-border-radius);
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}

	&-space {
		padding: 10px;
		border: 1px solid #6ea8fe;
		font-size: 13px;
		line-height: 150%;
		color: $color-blue-1;
		text-align: center;
		background: #e7f1ff;
		p:last-child {
			margin-bottom: 0;
		}
	}

	&-header {
		font: 16px/150% $font-tertirary;
	}

	&-button {
		border-radius: var(--bs-accordion-border-radius) var(--bs-accordion-border-radius) 0 0 !important;
		font-size: 14px;
		box-shadow: 0 1px 0 #dee2e6 !important;
		@include media-breakpoint-up(xl) {
			font-size: 16px;
		}

		&.collapsed {
			border-radius: var(--bs-accordion-border-radius) var(--bs-accordion-border-radius) var(--bs-accordion-border-radius)
				var(--bs-accordion-border-radius) !important;
			box-shadow: 0 1px 0 transparent !important;
		}
	}
}
