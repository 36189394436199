/* ==========================================================================
   RELATED
   ========================================================================== */
   .related {
    max-width: 1134px;
    padding: 60px 0 40px;
    margin: 0 auto;
    position: relative;
    background: $color-blue-5;
    @include media-breakpoint-up(lg) {
      padding-bottom: 70px;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 70px;
      padding-bottom: 100px;
    }
    @include media-breakpoint-up(xxl) {
      padding-bottom: 120px;
    }
    @include media-breakpoint-up(xxxl) {
      padding-bottom: 154px;
    }
  
    &::after {
      @include pseudo;
      width: calc(100vw + 2px);
      margin-left: -50vw;
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(50% - 1px);
      z-index: 0;
      background: $color-blue-5;
      @include media-breakpoint-up(xl) {
      }
    }
  
    .row {
      position: relative;
      z-index: 1;
    }
  
    &__heading {
      margin-bottom: 14px;
      font-size: 32px;
      text-align: center;
      color: $color-blue-2;
      @include media-breakpoint-up(lg) {
        font-size: 40px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 56px;
      }
    }
  
    .card {
      max-width: 280px;
      padding-bottom: 36px;
      margin: 0 auto 36px;
      position: relative;
      text-align: center;
      @include media-breakpoint-up(sm) {
        min-height: calc(100% - 36px);
        margin: 0 auto 36px;
      }
      @include media-breakpoint-up(lg) {
        max-width: none;
        min-height: 100%;
        margin: 0 -2px;
      }
  
      &-title {
        margin-right: -2px;
        margin-left: -2px;
        font-size: 18px;
        @include media-breakpoint-up(xl) {
          font-size: 20px;
        }
      }
  
      &-source {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
        color: #6c757d;
        text-align: left;
      }
  
      &-text {
        text-align: left;
      }
  
      .btn-primary {
        margin: auto;
        display: block;
        position: absolute;
        right: 0;
        bottom: 1rem;
        left: 50%;
        white-space: nowrap;
        transform: translateX(-50%);
      }
    }
  }
  