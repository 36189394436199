.form {
	&-header {
		padding-bottom: 37px;
		color: $color-blue-2;

		h2 {
			margin-bottom: 20px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 38px;
			}
		}

		p {
			font-size: 15px;
			line-height: 23px;
			font-weight: 300;
		}

		p.big {
			margin-bottom: 26px !important;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: -0.005em;
		}
		strong {
			font-weight: bold;
		}
		p:last-child {
			margin-bottom: 0;
		}
	}

	&-group {
		margin-bottom: 0.5em;
		letter-spacing: -0.02em;
	}

	&-label {
		margin-bottom: 0.5rem;
		font-family: $font-tertirary;
		font-weight: bold;
		letter-spacing: -0.01em;
	}

	&-control {
		padding: 0.5rem 1rem;
		border-color: #ced4da;
		font-family: $font-tertirary;

		&::placeholder {
			color: #6c757d;
			transition: opacity $effect;
			@include user-select;
		}
		&:focus::placeholder {
			opacity: 0;
		}
	}
}

.btn {
	&-primary {
		--bs-btn-bg: #{$color-blue-2};
		--bs-btn-border-color: #{$color-blue-2};
		--bs-btn-hover-bg: #{$color-blue-1};
		--bs-btn-hover-border-color: #{$color-blue-1};
		--bs-btn-active-bg: #{$color-blue-1};
		--bs-btn-active-border-color: #{$color-blue-1};
		--bs-btn-disabled-bg: #{$color-blue-2};
		--bs-btn-disabled-border-color: #{$color-blue-2};

		&.btn-arrow {
			&::after {
				@include pseudo;
				width: 14px;
				height: 14px;
				margin-left: 8px;
				display: inline-block;
				vertical-align: middle;
				position: relative;
				top: -1px;
				background: no-repeat center center;
				background-size: contain;
				background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZD0ibTEwLjQ3NyA2LjE2Ny00LjQ3LTQuNDdMNy4xODUuNTE5IDEzLjY2NyA3bC02LjQ4MiA2LjQ4Mi0xLjE3OC0xLjE3OCA0LjQ3LTQuNDdILjMzM1Y2LjE2N2gxMC4xNDRaIi8+PC9zdmc+");
				transition: transform $effect;
			}
			@media (hover: hover) {
				&:hover,
				&:focus {
					&::after {
						transform: translateX(3px);
					}
				}
			}
		}
	}
	&.btn-secondary {
		--bs-btn-bg: #{$color-orange};
		--bs-btn-border-color: #{$color-orange};
		--bs-btn-color: #{$color-blue-1};
		--bs-btn-hover-bg: #dbb447;
		--bs-btn-hover-border-color: #dbb447;
		--bs-btn-hover-color: #{$color-blue-1};
		--bs-btn-active-bg: #cba538;
		--bs-btn-active-border-color: #cba538;
		--bs-btn-active-color: #{$color-blue-1};
		--bs-btn-disabled-bg: #{$color-orange};
		--bs-btn-disabled-border-color: #{$color-orange};

		&.btn-eye {
			&::after {
				@include pseudo;
				width: 21px;
				height: 18px;
				margin-left: 8px;
				display: inline-block;
				vertical-align: middle;
				position: relative;
				top: -1px;
				background: no-repeat center center;
				background-size: contain;
				background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzFGM0E2OCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTAuNS41YzQuOTg0IDAgOS4xMyAzLjY2NCAxMCA4LjUtLjg2OSA0LjgzNi01LjAxNiA4LjUtMTAgOC41UzEuMzcgMTMuODM2LjUgOUMxLjM2OSA0LjE2NCA1LjUxNi41IDEwLjUuNVptLjAwMiAxNS4xMTJjMy44ODIgMCA3LjI0OC0yLjc0NCA4LjExMi02LjYxLS44NjctMy44NjUtNC4yMzItNi42MDQtOC4xMTItNi42MDRzLTcuMjQ1IDIuNzQtOC4xMTMgNi42MDNjLjg2NSAzLjg2NyA0LjIzIDYuNjEgOC4xMTMgNi42MTFabS0uMDA0LTIuMzYxYy0xLjQ4NiAwLTIuODU5LS44MS0zLjYwMi0yLjEyNWE0LjMzIDQuMzMgMCAwIDEgMC00LjI1IDQuMTQ0IDQuMTQ0IDAgMCAxIDMuNjAyLTIuMTI1YzEuNDg2IDAgMi44Ni44MSAzLjYwMiAyLjEyNWE0LjMzIDQuMzMgMCAwIDEgMCA0LjI1IDQuMTQ0IDQuMTQ0IDAgMCAxLTMuNjAyIDIuMTI1Wm0uMDA0LTEuODk0YzEuMjc3IDAgMi4zMTEtMS4wNTcgMi4zMTEtMi4zNiAwLTEuMzA1LTEuMDM0LTIuMzYyLTIuMzEtMi4zNjItMS4yNzcgMC0yLjMxMSAxLjA1Ny0yLjMxMSAyLjM2MSAwIDEuMzA0IDEuMDM0IDIuMzYxIDIuMzEgMi4zNjFaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=");
				transition: transform $effect;
			}
			@media (hover: hover) {
				&:hover,
				&:focus {
					&::after {
						transform: translateX(2px);
					}
				}
			}
		}
	}
}
