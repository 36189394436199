/* ==========================================================================
   SIGNUP
   ========================================================================== */
.cta-signup {
	color: white;
	display: none;
	padding: 3rem 0 5rem;
	position: relative;
	@include media-breakpoint-up(md) {
		padding: 5rem 0 8rem;
		display: block;
	}

	.sign-up-container {
		background-color: #325691;
		padding: 3rem;
		box-shadow: 2px 2px 2px #8080804d;
		border-radius: 30px;
		width: 100%;
	}

	&::after {
		@include pseudo;
		position: absolute;
		top: -100px;
		right: -1rem;
		bottom: 0;
		left: -1rem;
		z-index: 0;
		background: white;
		@include media-breakpoint-up(xss) {
			width: calc(100vw + 2px);
			right: auto;
			left: calc(50% - 1px);
			transform: translateX(-50%);
		}
		@include media-breakpoint-up(md) {
			background-image: url("#{$img}/bg/decal-20.webp"), url("#{$img}/bg/decal-21.webp");
			background-position:
				left bottom,
				right bottom;
			background-repeat: no-repeat, no-repeat;
			background-size:
				auto 165px,
				auto 195px;
		}
		@include media-breakpoint-up(xl) {
			background-size:
				auto 330px,
				auto 390px;
		}
	}

	> .row {
		position: relative;
		z-index: 1;
	}

	&__content {
		max-width: 1020px;
		margin: 0 auto;
		font: 500 24px/38px $font-heading;
		text-align: center;

		p {
			margin-bottom: 21px;
		}

		.btn {
			--bs-btn-padding-x: 5px;
			margin: 0 2px;
			min-width: 125px;
			box-shadow: 0 4px 10px rgba($color-black, 0.5);

			@include media-breakpoint-up(xl) {
				min-width: 177px;
				font-size: 20px;
			}

			&.btn-primary {
				--bs-btn-bg: #bedced;
				--bs-btn-border-color: #bedced;
				--bs-btn-color: #{$color-blue-1};
				--bs-btn-hover-bg: #a4c3d5;
				--bs-btn-hover-border-color: #a4c3d5;
				--bs-btn-hover-color: #{$color-blue-1};
				--bs-btn-active-bg: #81a6bb;
				--bs-btn-active-border-color: #81a6bb;
				--bs-btn-active-color: #{$color-blue-1};
				--bs-btn-disabled-bg: #bedced;
				--bs-btn-disabled-border-color: #bedced;

				&.btn-arrow {
					&::after {
						background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzFGM0E2OCIgZD0ibTEwLjQ3NyA2LjE2Ny00LjQ3LTQuNDdMNy4xODUuNTE5IDEzLjY2NyA3bC02LjQ4MiA2LjQ4Mi0xLjE3OC0xLjE3OCA0LjQ3LTQuNDdILjMzM1Y2LjE2N2gxMC4xNDRaIi8+PC9zdmc+");
					}
				}
			}
		}
	}
}
