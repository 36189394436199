/* ==========================================================================
   FOOTER
   ========================================================================== */
.footer {
	padding: 1rem 0;
	margin-top: auto;
	position: relative;
	z-index: 1;
	color: $color-white;
	background: linear-gradient(180deg, #2c518e 17%, #1a396c 69.5%, #0d2945 100%);
	cursor: default;
	@include media-breakpoint-up(md) {
	}
	@include media-breakpoint-up(xl) {
		padding-top: 55px;
		padding-bottom: 55px;
	}

	.container {
		max-width: 1096px;
		position: relative;

		.row {
			position: relative;
			&:first-child {
				@include media-breakpoint-up(lg) {
					.col-lg-6 {
						width: 42.5%;
					}
					.col-lg-2 {
						width: 20.2%;
						&:nth-child(4) {
							width: 17.1%;
						}
					}
				}
			}
			&:last-child {
				@include media-breakpoint-up(lg) {
					padding-top: 8px;
					margin-top: 16px;
					&::before {
						@include pseudo;
						height: 1px;
						position: absolute;
						top: 0;
						right: 20px;
						left: 20px;
						background: $color-grey-light;
					}
				}
			}
		}
	}

	&__logo {
		display: none;
		@include media-breakpoint-up(lg) {
			margin-bottom: 20px;
			display: block;
		}

		img {
			height: auto;
			display: block;
			@include user-select;
			@include media-breakpoint-down(xl) {
				max-width: 180px;
			}
		}
	}

	&__contacts {
		margin-bottom: 30px;
		display: none;
		font: 13px/150% $font-secondary;
		color: $color-grey-light;
		@include media-breakpoint-up(md) {
			display: block;
		}
		@include media-breakpoint-up(xl) {
			padding-right: 50px;
			margin-bottom: 0;
			font-family: $font-secondary;
			font-size: 15px;
			line-height: 22px;
			letter-spacing: -0.025em;
		}

		p {
			@include media-breakpoint-up(xl) {
				margin-bottom: 22px;
				&:first-of-type {
					margin-bottom: 42px;
				}
			}
			&:last-of-type {
				margin-bottom: 0;
			}

			&.location {
				padding-left: 21px;
				position: relative;
				background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzlCQTdCMyIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTAuMDcgOC45NDFjMS44Mi0xLjYzNyAxLjc2NC00LjIxNC0uMTI1LTUuNzkzLTEuODktMS41OC00LjkxNi0xLjU4LTYuODA1IDAtMS44OSAxLjU4LTEuOTQ1IDQuMTU2LS4xMjUgNS43OTMuNzYxLTEuMDM0IDIuMDk0LTEuNjYyIDMuNTI3LTEuNjYxIDEuNDM0IDAgMi43NjYuNjI3IDMuNTI4IDEuNjYxWm0tNi4wMzUuODUgMi41MDggMi4xMzRMOS4wNTEgOS43OUM4LjU5MiA4Ljk2NSA3LjYxNiA4LjQ0IDYuNTQzIDguNDRjLTEuMDcyIDAtMi4wNDkuNTI2LTIuNTA4IDEuMzUxWm0yLjUxIDMuODE2LTQuNDE2LTMuNzZjLTIuNDM4LTIuMDc2LTIuNDM4LTUuNDQyIDAtNy41MTggMi40MzktMi4wNzcgNi4zOTItMi4wNzcgOC44MyAwIDIuNDQgMi4wNzYgMi40NCA1LjQ0MiAwIDcuNTE5bC00LjQxNCAzLjc2Wm0uMDA0LTguMTI0Yy4zODQgMCAuNjk0LS4yNjUuNjk0LS41OSAwLS4zMjctLjMxLS41OTItLjY5NC0uNTkyLS4zODMgMC0uNjkzLjI2NS0uNjkzLjU5MSAwIC4zMjYuMzEuNTkuNjkzLjU5Wm0tLjAwMyAxLjE3MWMtLjc0NCAwLTEuNDMtLjMzOC0xLjgwMy0uODg2YTEuNTQ3IDEuNTQ3IDAgMCAxIDAtMS43NzJjLjM3Mi0uNTQ5IDEuMDYtLjg4NyAxLjgwMy0uODg3Ljc0MyAwIDEuNDMuMzM4IDEuODAyLjg4N2ExLjU0NyAxLjU0NyAwIDAgMSAwIDEuNzcyYy0uMzcxLjU0OC0xLjA1OS44ODYtMS44MDIuODg2WiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+")
					no-repeat 3px center;
				@include media-breakpoint-up(xl) {
					font-size: 13px;
				}
			}
		}
	}

	&__nav {
		@include media-breakpoint-down(md) {
			margin-bottom: 3px;
		}
		@include media-breakpoint-up(lg) {
			padding-top: 14px;
		}

		h5 {
			padding: 0.5rem 1rem;
			border-bottom: 1px solid $color-white;
			margin-bottom: 2px;
			font: bold 20px/150% $font-default;
			@include media-breakpoint-down(xss) {
				padding-right: 10px;
				padding-left: 10px;
			}
			@include media-breakpoint-up(md) {
				padding-right: 10px;
				padding-left: 10px;
			}
			@include media-breakpoint-up(lg) {
				border-bottom: none;
				font: 600 16.5px/150% $font-secondary;
			}
		}
	}

	&__nav ul,
	&__links {
		padding: 0;
		margin: 0;
		list-style: none;
		font-size: 14px;
		font-family: $font-secondary;
		position: relative;

		@include media-breakpoint-down(md) {
			li:not(:last-of-type) {
				margin-bottom: -1px;
			}
		}

		a {
			padding: 10px;
			display: inline-block;
			color: $color-grey-light;
			text-decoration: none;
			transition: color $effect;
			outline: none;
			@include media-breakpoint-up(lg) {
				padding-top: 7px;
				padding-bottom: 7px;
			}
		}
	}
	&__nav ul {
		a {
			@include media-breakpoint-up(md) {
				@media (hover: hover) {
					&:hover,
					&:focus {
						color: $color-white;
					}
				}
			}
		}
	}

	&__links {
		margin-top: -4px;
		@include media-breakpoint-up(md) {
			margin-top: 0;
			display: flex;
			justify-content: center;
		}
		@include media-breakpoint-up(md) {
			@include media-breakpoint-down(lg) {
				position: absolute;
				top: 68px;
				right: 0;
				left: 0;
			}
		}
		@include media-breakpoint-up(lg) {
			padding: 3px 9px 0 0;
			font: 12px $font-secondary;
			letter-spacing: 0;
			justify-content: flex-end;
		}

		li + li {
			@include media-breakpoint-up(md) {
				margin-left: 10px;
			}
		}

		a {
			//text-decoration: underline;
			@include media-breakpoint-up(md) {
				padding: 0;
			}

			@media (hover: hover) {
				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
		}
	}

	&__social {
		padding: 14px 0 0;
		border-top: 1px solid $color-white;
		margin-top: 36px;
		display: flex;
		justify-content: center;
		list-style: none;
		@include media-breakpoint-up(md) {
			@include media-breakpoint-down(lg) {
				padding-top: 18px;
				margin-top: 6px;
				margin-bottom: 48px;
			}
		}
		@include media-breakpoint-up(lg) {
			padding: 0;
			border: none;
			margin: 0;
			position: absolute;
			top: -52px;
			right: 41px;
		}

		li {
			padding: 0 2px;
			padding-right: 1rem;
			@include media-breakpoint-up(lg) {
				padding: 0 5.5px;
			}
		}

		a {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			background: rgba($color-white, 0.05);
			transition: background-color $effect;
			outline: none;
			@media (hover: hover) {
				&:hover,
				&:focus {
					background: rgba($color-white, 0.1);
				}
			}
		}
	}

	&__copyright {
		padding: 0.5rem;
		margin: 0;
		position: relative;
		font: 14px $font-secondary;
		letter-spacing: 0.015em;
		text-align: center;
		color: $color-grey-light;
		@include media-breakpoint-up(md) {
			padding: 0;
			text-align: center;
		}
		@include media-breakpoint-up(lg) {
			text-align: left;
		}
	}
}
