/* ==========================================================================
   PRICING
   ========================================================================== */
.pricing {
	padding-top: 40px;
	@include media-breakpoint-up(md) {
		padding-top: 60px;
		padding-bottom: 30px;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 128px;
		padding-bottom: 152px;
	}

	.col {
		@include media-breakpoint-down(xl) {
			padding-bottom: 54px;
		}
	}

	.card {
		max-width: 360px;
		border: none;
		border-radius: 4px;
		margin: 0 auto;
		position: relative;
		box-shadow: 0 3px 5px rgba($color-black, 0.3);
		@include media-breakpoint-up(md) {
			min-height: 100%;
		}
		@include media-breakpoint-up(xl) {
			margin-right: -6px;
			margin-left: -6px;
		}

		&::before {
			@include pseudo;
			width: 48px;
			height: 48px;
			border-radius: 50%;
			margin: 0 auto;
			position: absolute;
			top: -30px;
			right: 0;
			left: 0;
			z-index: -5;
			background: $color-blue-5;
			box-shadow: 0 3px 4px rgba($color-black, 0.4);
		}
		&::after {
			@include pseudo;
			width: 80%;
			max-width: 245px;
			height: 91px;
			border-radius: 40px;
			margin: 0 auto;
			position: absolute;
			right: 0;
			left: 0;
			bottom: -24px;
			z-index: 2;
			background: linear-gradient(180deg, $color-sky 55%, rgba($color-sky, 0.79) 99.99%, rgba($color-sky, 0) 100%);
			box-shadow: 0 2px 4px rgba($color-black, 0.4);
		}

		&-icon {
			width: 48px;
			height: 48px;
			border-radius: 50%;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: -30px;
			right: 0;
			left: 0;
			background: $color-blue-5;

			img {
				display: block;
			}
		}

		&-header {
			padding: 31px 30px 16px;
			height: 115px;
			border: none;
			background: none;

			h3 {
				margin: 0;
				color: $color-dark;
			}
		}

		&-body {
			padding-right: 30px;
			padding-left: 30px;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: 0.01em;
			color: $color-dark;
			text-align: left;

			ul {
				padding: 0;
				margin: 0 0 0 20px;
				//list-style: none;
			}
		}

		&-price {
			margin-bottom: 15px;
			font: bold 33px/29px $font-secondary;
			color: $color-dark;

			sup {
				top: -0.55em;
				font-size: 22px;
				font-weight: 600;
			}

			small {
				display: block;
				font-size: 12px;
				line-height: 16px;
			}
		}

		&-fees {
			margin-bottom: 14px;
			font: bold 12px $font-secondary;
			color: $color-grey;
		}

		&-time {
			margin-bottom: 0;
			font: 600 20px $font-secondary;
			color: $color-grey;
		}

		&-footer {
			padding: 0 30px 30px;
			border: none;
			border-radius: 4px;
			position: relative;
			z-index: 5;
			background: $color-white;
			box-shadow: 0 2px 2px rgba($color-black, 0.2);

			.pricing-started {
				width: 100%;
				font: 500 16px $font-heading;
			}

			.btn {
				--bs-btn-bg: #bedced;
				--bs-btn-border-color: #bedced;
				--bs-btn-color: #{$color-blue-2};
				--bs-btn-hover-bg: #a4c3d5;
				--bs-btn-hover-border-color: #a4c3d5;
				--bs-btn-hover-color: #{$color-blue-1};
				--bs-btn-active-bg: #81a6bb;
				--bs-btn-active-border-color: #81a6bb;
				--bs-btn-active-color: #{$color-blue-1};
				--bs-btn-disabled-bg: #bedced;
				--bs-btn-disabled-border-color: #bedced;
				margin-top: 13px;
				line-height: 24px;
			}
		}
	}
}
