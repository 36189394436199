/* ==========================================================================
   FAQ
   ========================================================================== */
.form-contact {
	max-width: 880px;
	padding: 30px 0 50px;
	margin: 0 auto;
	@include media-breakpoint-up(md) {
		padding-top: 50px;
		padding-bottom: 70px;
	}
	@include media-breakpoint-up(md) {
		padding-top: 71px;
		padding-bottom: 96px;
	}

	&__hours {
		margin-bottom: 4px;
		font-family: $font-secondary;
		font-size: 13px;
		font-weight: bold;
		color: rgba($color-blue-2, 0.7);
	}

	.form-control {
		color: #6c757d;
		@include media-breakpoint-up(xl) {
			font-size: 20px;
			font-weight: 300;
		}
	}
}

#message-sent,
#message-error {
	position: relative;
	z-index: 1;
}
